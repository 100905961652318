import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: {
      title: 'Cap-Connect | Accueil',
      description: 'Cap-Connect : basé à Audierne, assistance informatique, réparation mobile, dépannage à domicile dans le Cap-Sizun et alentours'
    }
  },
  {
    path: '/prestations',
    name: 'prestations',
    component: () => import('../views/PrestaView.vue'),
    meta: {
      title: 'Cap-Connect | Prestations',
      description: 'Cap-Connect | Assistance informatique et téléphonie, dépannage à domicile dans le Cap-Sizun et alentours : détails des prestations'
    }
  },
  {
    path: '/tarifs',
    name: 'tarifs',
    component: () => import('../views/PricesView.vue'),
    meta: {
      title: 'Cap-Connect | Tarifs',
      description: 'Cap-Connect | Assistance informatique et téléphonie, dépannage à domicile dans le Cap-Sizun et alentours : tarifs des prestations'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue'),
    meta: {
      title: 'Cap-Connect | Contact',
      description: 'Cap-Connect | Assistance informatique et téléphonie, dépannage à domicile dans le Cap-Sizun et alentours : coordonnées de contact'
    }
  },
  {
    path: '/mentions-legales',
    name: 'legal',
    component: () => import('../views/LegalView.vue'),
    meta: {
      title: 'Cap-Connect | Mentions légales',
      description: 'Cap-Connect | Assistance informatique et téléphonie, dépannage à domicile dans le Cap-Sizun et alentours : mentions légales'
    }
  },
  {
    path: '/404-not-found',
    name: '404View',
    component: () => import('../views/404View.vue')
  },
  {
    path: '/:catchAll(.*)', redirect:'404-not-found'
  } 
]

const router = createRouter({
  history: createWebHashHistory(),
  routes, 
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
})


export default router
