<template>
  <header>
    <div class="img-header">
      <img src="../assets/logo.png" alt="logo de cap-connect représentant un ordinateur">
      <div>
        <p class="title">Cap-Connect</p>
        <p class="resume">Services informatiques, téléphonie, dépannage à domicile</p>
      </div>
    </div>

    <div class="menu-to-fix">
    <div class="deco">
      <div class="triangle-left"></div>
      <div class="triangle-right"></div>
    </div>
    <div class="computer-menu">
      <ul>
        <li>
          <router-link :to="{name: 'home'}">
            <img class="logo" src="../assets/prez.png" alt="logo de présentation">
            <p>Présentation</p>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'prestations'}">
            <img class="logo" src="../assets/presta.png" alt="logo représentant un ordinateur et un portable">
            <p>Prestations</p>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'tarifs'}">
            <img class="logo" src="../assets/bill.png" alt="logo représentant une facture">
            <p>Tarifs</p>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'contact'}">
            <img class="logo" src="../assets/contact.png" alt="logo représentant une enveloppe et un téléphone">
            <p>Contact</p>
          </router-link>
        </li>       
      </ul>
    </div>
    </div>

  </header>
</template>

<script>

export default {
    name: 'MenuComponent',

    methods: {
      handleScroll() {
        const menu = document.querySelector('.menu-to-fix');
        const scrollY = window.scrollY;

        if (scrollY > 180) {
          menu.classList.add('fixed-menu');
        } else {
          menu.classList.remove('fixed-menu');
        }
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
    }
}

</script>


<style lang="scss" scoped>

.fixed-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: #e9e6e6;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px 0px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  transition : 2s;
}

header{
  background-color: #e9e6e6;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px 0px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  .img-header{
    width:100%;
    height : 160px;
    background-image: 
    linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.7)),
      url("../assets/couv.JPG") ;
    background-size:cover;
    background-position: bottom;
    display: flex;
    align-content: center;
    justify-content: center;
    color : white;
    img{
      height : 70%;
      width : auto;
      margin : auto 0 auto auto;
    }
    div{
      margin : auto auto auto 0;
      .title{
        font-size : 3.2rem;
        font-weight:bold;
      }
      .resume{
        font-size: 2rem;
      }
    }
  }

  .deco{
    display : flex;
    justify-content : space-between;
    .triangle-left{
      display : inline-block;
      height : 0;
      width : 0;
      border-top : 30px solid #E31821;
      border-right : 700px solid transparent;
    }
    .triangle-right{
      display : inline-block;
      height : 0;
      width : 0;
      border-top : 30px solid #E31821;
      border-left : 700px solid transparent;
      margin-right:0;
    }
  }
  .computer-menu{
    .router-link-exact-active{
      position:relative;
    }
    .router-link-exact-active:before {
      border-bottom : 3px solid #E31821;
      transition: .3s;
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      border-bottom: 2px solid #E31821;
      animation: border_anim 200ms linear forwards;
    }
    @keyframes border_anim {
      0%{
        width: 0%;
      }
      100%{
        width: 100%;
      }
    }

    display : flex;
    justify-content: center;
        ul{
          display: flex;
          flex-direction: row;
          li{
            list-style: none;
            margin : 0 2rem 1rem 2rem;
            display: flex;
            flex-direction: column;
            font-size : 1.2rem;
            text-align: center;
            a{
              text-decoration: none;
              color : black;
              .logo{
                height : 35px;
                width : auto;
                margin : auto;
              }
            }
            a:hover{
              transform: translateY(-1rem) scale(1.2);
              transition : 500ms;
            }
          }
        }
      }
}





</style>

