<template>
  <footer>
    <div class="footer-content">
      <div>
        <h4>Autres informations</h4>
        <p><router-link :to="{name: 'legal'}">
          Mentions légales
        </router-link></p>
        <!-- <p><a href='#'>On en parle !</a></p> -->
      </div>

      <div>
        <h4>Contact</h4>
        <p><a href="tel:+33666394956">06.66.39.49.56</a></p>
        <p><a class='contact' href="mailto:cap.connect29@gmail.com">cap.connect29@gmail.com</a></p>
      </div>

      <div class='footer-social'>
        <a href="https://www.facebook.com/profile.php?id=100093695023812" target="_blank"><img src="../assets/fbblack.png" alt="logo de facebook"></a>
        <a href="https://instagram.com/cap.connect?igshid=MzRlODBiNWFlZA=="  target="_blank"><img src="../assets/instablack.png" alt="logo de instagram"></a>
        <a href="https://fr.linkedin.com/in/cap-connect-thibault-masson" target="_blank" ><img src="../assets/linkedinblack.png" alt="logo de linkedin"></a>
      </div>

      <div class='copyright'>
        <div>
          <img src="../assets/logonoir.png" alt="logo de cap-connect représentant un ordinateur">
        </div>
        <div class="details">
          <h4>© {{ currYear }} | Cap-Connect</h4>
          <p class="dev-link"> Site développé par <a href="https://fr.linkedin.com/in/samantha-bain-cornu" target="_blank">Samantha Bain-Cornu</a></p>
        </div>
      </div>
    </div>


  </footer>
</template>

<script>

// import index from '@/store/index.js'


export default {
    name: 'FooterComponent',

  data() {
    return {
      currYear: new Date().getFullYear(),
    }
  }
}
</script>


<style lang="scss" scoped>
// @import "@/assets/scss/variables.scss";

footer{
  color:black;
  background: rgb(227,24,33);
  background: linear-gradient(156deg, rgba(227,24,33,1) 0%, rgba(244,121,107,1) 90%);
  box-shadow: rgba(50, 50, 93, 0.25) 27px 13px 27px 13px, rgba(0, 0, 0, 0.3) 0px 8px 16px -16px;

  font-size : 1rem;
  height : 115px;
  text-align: center;
  width : 100%;
  display: flex;

    .footer-content{
      width : 100%;
      background-color: #E9E6E6;
      box-shadow: rgba(50, 50, 93, 0.25) 27px 13px 27px 13px, rgba(0, 0, 0, 0.3) 0px 8px 16px -16px;
      margin : 0.5rem 0 0 0;
      padding-top : 1rem;
      display : flex;
      justify-content: space-around;
      padding-bottom : 1rem;
      h4{
        margin-bottom : 0.5rem;
      }
      a{
        // text-decoration: none;
        font-weight : bold;
        color : rgb(250, 93, 93);
      }
      .contact{
        text-decoration: none;
      }
      .footer-social{
        margin-top :auto;
        margin-bottom: auto;
        img{
          height : 40px;
          width : auto;
          margin-left : 1rem;
        }
      }
      .footer-social > a>img:hover{
        transform : scale(1.2);
        transition : 300ms;
      }
      .copyright{
        display : flex;
        .details{
          margin-top : auto;
          margin-bottom: auto;
          .dev-link{
            opacity : 0.4;
          }
        }
        div{
          img{
          height : 90%;
          width : auto;
          margin : auto;
      }
        }
      }
  }
}





</style>

