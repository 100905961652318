<template>
  <MenuComponent />
  <transition name="fade" mode="out-in">
    <router-view/>
  </transition>
  <FooterComponent />
</template>


<script>

import MenuComponent from '@/components/MenuComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';





export default {
  name: 'App',
  components: {
    MenuComponent,
    FooterComponent,
  },

  methods : {
  }

}

</script>


<style lang="scss">

@import 'ress';

@import url('https://fonts.googleapis.com/css2?family=Bubbler+One&family=Cabin&family=Cuprum:wght@500&family=Exo:wght@300&family=Open+Sans:ital,wght@0,300;0,500;1,300;1,500&display=swap');


#app {
  font-family: Exo, sans-serif;
  font-size : 23px;
  background-color: #f5f5f5;
  h1{
    font-size : 1.6em;
  }
  strong{
    background: rgb(227,24,33);
    background: linear-gradient(156deg, rgba(227,24,33,1) 0%, rgba(244,121,107,1) 90%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;  
    font-weight : bold;
  }

  @media (max-width: 1400px) {
    header{
        div{
          .title{
            font-weight:bold;
          }
          .resume{
            font-size: 1.5rem;
          }
        }
      .triangle-left{
        display : inline-block;
        height : 0;
        width : 0;
        border-top : 30px solid #E31821;
        border-right : 450px solid transparent;
      }
      .triangle-right{
        display : inline-block;
        height : 0;
        width : 0;
        border-top : 30px solid #E31821;
        border-left : 450px solid transparent;
        margin-right:0;
      }
    }


  }
    @media (max-width: 900px) {
      font-size : 22px;
      .first-prez{
        margin-top : 2rem;
      }
      .contact-page{
        margin-top : 3rem;
      }
      header{
        .img-header{
          height : 150px;
        }
        div{
          .title{
            font-size : 2.5rem;
            font-weight:bold;
          }
          .resume{
            font-size: 1.2rem;
          }
        }
      .triangle-left{
        display : inline-block;
        height : 0;
        width : 0;
        border-top : 30px solid #E31821;
        border-right : 195px solid transparent;
      }
      .triangle-right{
        display : inline-block;
        height : 0;
        width : 0;
        border-top : 30px solid #E31821;
        border-left : 195px solid transparent;
        margin-right:0;
      }
      li{
        font-size : 1rem;
        margin : 0 1rem 1rem 1rem;
        .logo{
          height : 30px;
        }
      }
    }
  }
   @media (max-width: 600px) {
     font-size : 18px;
      header{
        .img-header{
          height : 90px;
        }
        div{
          .title{
            font-size : 1.6rem;
            font-weight:bold;
          }
          .resume{
            font-size: 0.9rem;
          }
        }
      .triangle-left{
        display : inline-block;
        height : 0;
        width : 0;
        border-top : 15px solid #E31821;
        border-right : 100px solid transparent;
      }
      .triangle-right{
        display : inline-block;
        height : 0;
        width : 0;
        border-top : 15px solid #E31821;
        border-left : 100px solid transparent;
        margin-right:0;
      }
      li{
        font-size : 0.8rem;
        margin : 0 0.5rem 0.5rem 0.5rem;
        .logo{
          height : 23px;
        }
      }
      a:hover{
        transform: scale(1.1);
        transition : 500ms;
      }
    }
  }
  @media (max-width: 600px) {
    font-size : 15px;
  }

    @media (max-width: 850px) {
      footer{
        height : auto;
        font-size : 12px;
        .footer-content{
        flex-direction : column;
        div{
          margin : 0.5rem;
        }
        .copyright{
          margin : 1rem auto 1rem auto;
          text-align: left;
          border-top : 1px solid grey;
        }
        img{
          height : 30px;
          width : auto;
        }
      }
    }
  }

  /* Transitions lors du changement de pages, commenté le temps de voir si c'est ça qui cause le bug sur les menus */
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  // les styles responsive des pages prix et presta
  .prices-page{
    @media(max-width:1600px){
      width:90%;
    }
    @media (max-width:1200px){
      .line{
          width:70%;
      }
      .last-prices{
        margin : 2rem auto 0rem auto;
        .col2{
          margin : 4rem auto 3rem auto;
        }
      }
      .first-prices, .last-prices{
        flex-direction: column;
          .col1, .col2{
            width:100%;
          }
      }
      .mobile-table, .formation-table{
        margin-top : 4rem;
      }
    }
    @media (max-width:600px){
        .name, b, .price, p{
          font-size : 15px;
        }
        h2{
          font-size:23px;
        }
        .prices-container{
          margin-top:2rem;
        }
        .first-prez{
          margin-bottom:2rem;
        }
    }
  }

   .presta-page{
      @media(max-width:1600px){
        width:90%;
        .buttons{
          margin : 3rem 0 1rem 0;
        }
        .presta-box{
          width : 95%;   
          .box-text{
            width:60%;
          }
        }
      }
      @media(max-width:1200px){
         .presta-box{
           flex-direction : column;
           margin : 1rem auto 0 auto;
           padding : 0.8rem;
           .box-text{
              width:100%;
            }
         }
          button{
            width : 25%;
          }
          img{
            width:100%;
            height : auto;
            margin:auto;
            object-fit: contain;
          }
          .presta-cat > .domotic-box > .box-img{
            order:1;
          }
      }
      @media(max-width:600px){
        width : 100%;
        .first-prez{
          width : 90%;
        }
        button{
        height : 4.5rem;
        }
        .active{
          height : 6rem;
        }
      }
      @media (min-width : 1200px){
          .box-text{
            width:65%;
        }
          .box-img{
            width:35%;
        }
      }
   }




}




</style>
